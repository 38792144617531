import settings from './settings.js'



(function() {

  /**
 * Loading bar 24
 */
  if(!['md', 'xl', 'xxl'].includes(settings.viewSize)) return
  const head = document.querySelectorAll('head')[0]
  const script = document.createElement('script')
  script.src = '//services.delo.si/delostat/bar24/app.min.js'
  head.appendChild(script);

  /**
   * Shoe Story editor ID in console
   */
  const seElement = document.getElementById('seid')
  if (seElement) {
    const seid = seElement.getAttribute('value')
    if (seid)
      console.log(`%c SE ID: ${seid} `, 'background: darkblue; color: yellow')
  }
})()

