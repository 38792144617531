import crel from 'crel'
import settings from '../../../src/es6/settings'
import { Q, toggleClass } from '../../../src/es6/util'
import infiniteMenu from '../../../src/es6/infinite_menu'


['scroll', 'resize'].forEach(ev => window.addEventListener(ev, menu1))

function menu1() {
    const elMenu1 = Q('.menu1')
    const elMenu1Fixed = Q('.menu1Fixed')
    const elMenuPos = elMenu1.getBoundingClientRect()

    elMenuPos.top > 0
        ? elMenu1Fixed.classList.add('removed')
        : elMenu1Fixed.classList.remove('removed')
}

window.addEventListener('load', ()=>{
    if(Q('.menu3__btn_burger')) Q('.menu3__btn_burger').addEventListener('click', e => {
        e.preventDefault()
        toggleClass(Q('.menu4'), 'hidden')
        toggleClass(Q('.menu3__btn_burger .fas'), ['fa-bars', 'fa-times'])
    })
})


/**
 * To reactivate infinite menus uncomment the following
 * but also remove XXXX in main_menu.html -> menu1__item hiddenXXX
 */
// infiniteMenu('.menu1', 500)
// infiniteMenu('.menu1Fixed', 600)
