(function () {
    if (window.checkCookie("druzabni") == true) {
        window.fbAsyncInit = function () {
            FB.init({
                appId: '5805328152852544',
                xfbml: true,
                version: 'v8.0'
            });
            FB.AppEvents.logPageView();
        };

        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/sl_SI/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }
})()
