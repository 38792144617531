import crel from 'crel'
import $ from 'jquery'
import settings from './settings'
/**
 * Login and sign up
 */
window.addEventListener('load', function() {
    // Render recaptcha
    const gRecaptcha = grecaptcha.render('g-recaptcha_sk1', {'sitekey': settings.recaptcha_site_key})
    const min_password_length = 4
    const modal_register = $('#modal_register')
    const modal_login = $('#modal_login')
    // Reveal register company
    $('#register_form__is_company').click(e => {
        let action = e.target.checked ? 'removeClass' : 'addClass'
        $('.register_company')[action]('hidden')
    });
    function get_registration_data() {
        let out =  {
            email: '',
            password1: '',
            password2: '',
            terms: false,
            is_company: false,
            first_name: '',
            last_name: '',
            company: '',
            street_1: '',
            street_2: '',
            postal_code: '',
            city: '',
            vat: '',
            grecaptchaResponse: '',
            url: ''
        }
        Object.keys(out).forEach(k => out[k] = $(`#register_form__${k}`).val())
        out.is_company = $('#register_form__is_company').is(":checked")
        return out
    }
    function validator(el, value, text) {
        if(!$(el).hasClass('validate')) return true
        value ? $(el).addClass('valid').removeClass('invalid') : $(el).addClass('invalid').removeClass('valid')
        if(text && !value)
            $(el).parent().find('.helper-text').attr('data-error', text)
        return value
    }
    function equator(arr) {
        return !arr.length ? false : arr.reduce((a, b) => a + b, false) == arr.length
    }
    $('.register_form').on('change mouseup mouseout keyup', e => {
        const data = get_registration_data()
        /**
         * emails
         */
        let email_tests = true
        if ( data.email ) {
            if ( !data.email.match(settings.mail_regex) ) {
                validator($('#register_form__email'), false, 'Nepravilen e-naslov')
                email_tests = false
            } else {
                validator($('#register_form__email'), true)
            }
        }
        /**
         * Passwords
         */
        let passwd_tests = true
        if(!data.password1 && !data.password2) passwd_tests = false
        if(data.password1.length < min_password_length) passwd_tests = false
        if(data.password2.length < min_password_length) passwd_tests = false
        if(data.password1 != data.password2) {
            validator($('#register_form__password1'), false, 'Gesli se ne ujemata')
            passwd_tests = false
        } else {
            validator($('#register_form__password1'), true)
        }

        /**
         * general agreement
         */
        let terms_tests = false
        if($('#login_form__terms').is(":checked")) terms_tests = true
        if(!terms_tests) validator($('#login_form__terms'), false, 'Morate se strinjati s splošnimi pogoji')

        // If we are not registring a company
        if ( !$('#register_form__is_company').is(':checked') ) {
            email_tests && passwd_tests && terms_tests
            ? $('.register_form__send_button').removeClass('disabled')
            : $('.register_form__send_button').addClass('disabled')
            return
        }
        /**
         * company
         */
        let company_tests = [...$('.register_form .register_company .validate')].map(el => {
            return  $(el).attr("type") == 'checkbox' ? $(el).is(":checked") : $(el).val()
        }).every(item => item)
        email_tests && passwd_tests && company_tests
        ? $('.register_form__send_button').removeClass('disabled')
        : $('.register_form__send_button').addClass('disabled')


    })
    $('.register_form').on('submit', e => {
        e.preventDefault()
        const registration_data = get_registration_data()
        registration_data.grecaptchaResponse = grecaptcha.getResponse()
        registration_data.url = window.location.href
        $.ajax({
            url: `${settings.ella_url}/apiv1/accounts/create/`,
            method: 'POST',
            data: JSON.stringify(registration_data),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
        }).done(data => {
            modal_register.find('.col').empty()
            modal_register.find('.col').append(
                crel('h4', 'Registracija uspešna!'),
                crel('p', 'Na vaš elektronski naslov smo poslali potrditveno sporočilo.'),
                crel('p', 'S klikom na povezavo v sporočilu lahko aktivirate svoj račun.')
            )
            M.Modal.getInstance(modal_register).open()
            M.Modal.getInstance(modal_login).close()
        }).fail(xhr => {
            modal_register.find('.col').empty()
            modal_register.find('.col').append(
                crel('h4', 'Registracija ni bila uspešna!'),
                crel('ul',
                    xhr.responseJSON.errors.map(item => {
                        return crel('li', item.message)
                    })
                )
            )
            M.Modal.getInstance(modal_register).open()
            M.Modal.getInstance(modal_login).close()
        })
    })
})


function validator(el, value, text) {
    if(!$(el).hasClass('validate')) return true
    value ? $(el).addClass('valid').removeClass('invalid') : $(el).addClass('invalid').removeClass('valid')
    if(text && !value)
        $(el).parent().find('.helper-text').attr('data-error', text)
    return value
}
function equator(arr) {
    return !arr.length ? false : arr.reduce((a, b) => a + b, false) == arr.length
}
function get_registration_data() {
    let out =  {
        email: '',
        password1: '',
        password2: '',
        terms: false,
        is_company: false,
        first_name: '',
        last_name: '',
        company: '',
        street_1: '',
        street_2: '',
        postal_code: '',
        city: '',
        vat: '',
        grecaptchaResponse: '',
        url: ''
    }
    Object.keys(out).forEach(k => out[k] = $(`#register_form__${k}`).val())
    out.is_company = $('#register_form__is_company').is(":checked")
    return out
}

function init(x) {
    // console.log(asd)
}
window.addEventListener('load', init)

export {
    validator,
    equator,
    get_registration_data,
    init
}