function g() {

  const gElement = document.getElementById('dataGallery')
  if(!gElement) return

  const gallery_images_data = JSON.parse(gElement.textContent)

  const gallery_images = gallery_images_data.map(image => {
    const spec = image.filter_spec['max-1280x1280']
    return {
      id: image.image_id,
      src: `${spec.path}?rev=${spec.rev}`,
      h: spec.height,
      w: spec.width,
      title: image.caption
    }
  })

  if(!gallery_images) return // If we do not have gallery images, we do not attempt to create any
  const pswpElement = document.querySelector('.pswp') // gallery element
  const gallery_images_ids = gallery_images.map(item => item.id) // get gallery images ids
  const options = { // Set up some galery options
    index: 0,
    loop: true,
    shareButtons: [],
    bgOpacity: 0.85,
    preload: [3, 3],
    // - START zooming
    maxSpreadZoom: 2,
    getDoubleTapZoom: function (isMouseClick, item) {
        return item.initialZoomLevel * 2;
    },
    // UI options
    // zoomEl: false,
    // - END zooming
    addCaptionHTMLFn: function(item, captionEl, isFake) {
        if(!item.title) {
            captionEl.children[0].innerHTML = '';
            return false;
        }
        captionEl.children[0].innerHTML = decodeURIComponent(item.title);
        return true;
    },
  }
  const images = [...document.querySelectorAll('.article__main_image, .article__img-tag-visible')]

  function getImageId(element) {
    let img_id = element.src
    ? element.getAttribute('img_id')
    : element.querySelectorAll('img')[0].getAttribute('img_id') // element's image id
    return parseInt(img_id) // to integer
  }

  function galleryInit(event) {
    const pswpElement = document.querySelector('.pswp')
    const img_id = getImageId(event.currentTarget)
    if(!gallery_images_ids.includes(img_id)) return
    options.index = gallery_images_ids.indexOf(img_id) // Set the starting image of presentation
    let gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, gallery_images, options) // create gallery
    gallery.init() // start gallery
    //dm.AjaxEvent("pageview")
    //console.log('ajax pageview gallery open')
  }

  // We observe if article__content will change
  const article_tag = document.querySelectorAll('.article__content')[0]
  const config = { attributes: true, childList: true, characterData: true };
  const observer = new MutationObserver(function(mutations) {
    if (mutations[0].addedNodes[0].data=='   ')
      images.forEach(element => {
        element.addEventListener('click', galleryInit)
      }, {passive: true})
  });
  observer.observe(article_tag, config);

  // If there is no esi inside article__content, it has been prerendered, so we need to init a fake mutation on it
  if(!article_tag.getElementsByTagName('esi:include').length) article_tag.insertAdjacentHTML('beforeend', '   ')

}

document.addEventListener('DOMContentLoaded', g)
