import $ from 'jquery'
import M from 'materialize-css'

import {QA} from './util'



window.addEventListener('load', function () {
    M.Tabs.init(QA('.tabs'), {
        onShow: function () {
            //dm.AjaxEvent("pageview")
            //console.log('ajax pageview tab open')
        }
    })

    M.Modal.init(QA('.modal'), {
        onOpenStart: function () {
            //dm.AjaxEvent("pageview")
            //console.log('ajax pageview modal open')
        }
    })
    M.Tooltip.init(QA('.tooltipped'))

    const cSettings = {
        fullWidth: true,
        indicators: false,
        noWrap: false
    }
    const big = '.carousel.carousel-slider.big-authors'
    const small = '.carousel.carousel-slider.small-authors'
    M.Carousel.init(QA(big), cSettings)
    M.Carousel.init(QA(small), cSettings)

    $('.search__next_author').click(function (e) {
        $(e.currentTarget.parentElement).find(`${big}, ${small}`)
            .each(function (index) {
                M.Carousel.getInstance(this).next()
            })
    });

    $('.search__previous_author').click(function (e) {
        $(e.currentTarget.parentElement).find(`${big}, ${small}`)
            .each(function (index) {
                M.Carousel.getInstance(this).prev()
            })
    });
})

