const searchbutton = function (sel) {
    const el = document.querySelector(sel)
    const target = document.querySelector('.searchbox__container')
    if(!target || !el) return
    const focus_field = target.querySelector('.searchbox__field')
    if(!focus_field) return
    el.addEventListener('click', e => {
        //dm.AjaxEvent("pageview")
        //console.log('ajax pageview search open')
        e.preventDefault()
        target.classList.toggle('hide')
        target.classList.toggle('show')
        const test = target.classList.contains('show')
        setTimeout(() => {
            target.style.display = test ? 'block' : 'none'
            test ? focus_field.focus() : focus_field.blur()
        }, 0)
    })
}


// Z-indexing revisited (could probbably go some other file)

// document.addEventListener('DOMContentLoaded', () => {
//     const menu = document.querySelector('.mm2')
//     const content = document.querySelector('.container')
//     if (!menu || !content) return
//     menu.addEventListener('mouseover', e => {
//         menu.style.zIndex = 3
//         content.style.zIndex = 2
//     })
//     content.addEventListener('mouseover', e => {
//         menu.style.zIndex = 2
//         content.style.zIndex = 3
//     })
// })

export { searchbutton }
