import $ from 'jquery'
(()=>{
    if ($('.social_icons_mobile_footer .social__icon_facebook').length) {
        $('.social_icons_mobile_footer .social__icon_facebook').click(e => {
            ga('send', {
                hitType: 'event',
                eventCategory: 'Share',
                eventAction: 'Fb',
                eventLabel: window.location.href
            });
            console.log('Fb Share');
        })
    }
    if ($('.social_icons_mobile_footer .social__icon_twitter').length) {
        $('.social_icons_mobile_footer .social__icon_twitter').click(e => {
            ga('send', {
                hitType: 'event',
                eventCategory: 'Share',
                eventAction: 'Tw',
                eventLabel: window.location.href
            });
            console.log('Twitter');
        })
    }
    if ($('.social_icons_mobile_footer .social__icon_facebook-messenger').length) {
        $('.social_icons_mobile_footer .social__icon_facebook-messenger').click(e => {
            ga('send', {
                hitType: 'event',
                eventCategory: 'Share',
                eventAction: 'FbM',
                eventLabel: window.location.href
            });
            console.log('Fb Messenger');
        })
    }
})()
