import settings from  './settings'
import $ from 'jquery'


/**
 * ellaUserData promise
 */
const ellaUserData = new Promise((resolve, reject) => {
    fetch('/accounts/profile/?session=true')
        .then(res => res.json())
        .then(resolve)
        .catch(reject)
})


/**
 * Get session data into DOM
 */
$(async function() {
    let uData = await ellaUserData
    document.querySelector('.modal_logout__username').innerHTML = uData.authenticated ? uData.ident : 'Neznan prijavljen uporabnik'
    ;[...document.querySelectorAll('.main_menu__icon_link--logout')].forEach(el => {
        el.dataset.tooltip = `Odjava: ${uData.ident}`
        uData.authenticated ? el.classList.remove('hidden') : el.classList.add('hidden')
    })
    ;[...document.querySelectorAll('.main_menu__icon_link--login')].forEach(el => {
        uData.authenticated ? el.classList.add('hidden') : el.classList.remove('hidden')
    })
})


/*
;[...document.querySelectorAll('.modal_logout__logout_button')].forEach(async el => {
    const csrftoken = (await ellaUserData).csrftoken
    el.addEventListener('click', e => {
        e.preventDefault()
        let url = `${settings.ella_url}/accounts/logout/`
        let ifr = crel('iframe', {src: url, class: 'hidden'});
        ifr.onload = () => {
            let body = new FormData();
            body.append('csrftoken', csrftoken);
            fetch('/accounts/logout/', {
                method: 'POST',
                headers: {
                   'Content-Type': 'application/x-www-form-urlencoded',
                   'X-CSRFToken': csrftoken
                }
            }).then(() => location.reload())
        }
        crel(document.body, ifr);
    })
})
*/

$( document ).ready(function() {
;[...document.querySelectorAll('.modal_logout__logout_button')].forEach(async el => {
    const csrftoken = (await ellaUserData).csrftoken
    el.addEventListener('click', e => {
        e.preventDefault()
        let url = `${settings.ella_url}/accounts/logout/?logout_redirect=${window.location.href}`
        fetch('/accounts/logout/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-CSRFToken': csrftoken
            }
        }).then(() => window.location.href = url)
    })
})
})



// Menu login button set redirect cookie
$( document ).ready(function() {
    ;[...document.querySelectorAll('.socialaccount_provider.ellaprovider, .store_links a')].forEach(el => {
        el.addEventListener('click', e => {
            document.cookie = `POC_LOCAL_REDIRECT=${window.location.href};max-age=3600;path=/` // encodeURIComponent(window.location)
        })
    })
});


// Logout and login for user comming from google showcase
$( document ).ready(function() {
    ;[...document.querySelectorAll('.google-showcase a')].forEach(el => {
        el.addEventListener('click', async e => {
            e.preventDefault()
            // Set up redirect
            document.cookie = `POC_LOCAL_REDIRECT=${window.location.href};max-age=3600;path=/` // encodeURIComponent(window.location)
            const csrftoken = (await ellaUserData).csrftoken
            var res = await fetch('/accounts/logout/', {
                method: 'POST',
                headers: {
                   'Content-Type': 'application/x-www-form-urlencoded',
                   'X-CSRFToken': csrftoken
                }
            });
            const redirect_url = `/accounts/ellaprovider/login/?process=login`;
            let url = `${settings.ella_url}/accounts/logout/?logout_redirect=${window.location.origin+redirect_url}`;
            window.location = url
        })
    })
})


export default ellaUserData

'/accounts/logout/?logout_redirect=/'