import $ from 'jquery'
window.addEventListener('load', function() {
    if(location.protocol=='https:') showNotice();
})

function showNotice() {
    $(".notice").addClass('hidden');
    //ce cookie ne obstaja, prikazi normalno obvestilo
    if (getCookie("ALLOW_COOKIES") == null || getCookie("ALLOW_COOKIES") == 'brez') {
        $(".notice").addClass('annoying').removeClass('friendly hidden');
    }
}