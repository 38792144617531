import settings from '../../src/es6/settings'
import $ from 'jquery'


function getPosition(elm) {
  var xPos = 0, yPos = 0;
  while(elm) {
    xPos += (elm.offsetLeft - elm.scrollLeft + elm.clientLeft);
    yPos += (elm.offsetTop - elm.scrollTop + elm.clientTop);
    elm = elm.offsetParent;
  }
  return { x: xPos, y: yPos };
}

function showTeaserImage(img) {
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop
    let pos = getPosition(img).y - scrollTop
    if(pos < settings.windowHeight) {
        img.classList.add(img.dataset.lazy)
    }
}

function showPdfImage(img) {
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop
    let pos = getPosition(img).y - scrollTop
    if(pos < settings.windowHeight) {
        img.style.backgroundImage = `url(${img.dataset.bgimage})`;
    }
}

// function showTeaserImage(img) {
//     let rect = img.getBoundingClientRect()
//     if(rect.y < settings.windowHeight) {
//         img.classList.add(img.dataset.lazy)
//     }
// }
//
// function showPdfImage(img) {
//     if(img.style.backgroundImage) return
//     let rect = img.getBoundingClientRect()
//     if(rect.y < settings.windowHeight) {
//         img.style.backgroundImage = `url(${img.dataset.bgimage})`;
//     }
// }

$(function() {
    let teaserImages = [...document.querySelectorAll('.article_teaser__article-image')]
    let pdfImages = [...document.querySelectorAll('.last_print__image')]
    teaserImages.forEach(showTeaserImage)
    pdfImages.forEach(showPdfImage)
    window.addEventListener('scroll', () => {
        teaserImages.forEach(showTeaserImage)
        pdfImages.forEach(showPdfImage)
    })
})