import crel from 'crel'
import $ from 'jquery'
import M from 'materialize-css'

(function () {

    if(!$('.article__container').length) return


    // close button
    function xbutton() {
        const btn = crel('i', {
            class: 'far fa-times-circle',
            style: 'position: fixed; color: white; top: 1em; right: 1em;transform: scale(2);'
        })
        btn.addEventListener('click', () => mui.overlay('off'))
        return btn
    }

    /*************************************************************************************** */
    const style = "text-align: center;background: none;"
    const modal = {
        audio:  crel('div', {id: 'modal_audio', class: "modal", style: "text-align: center; background-color: none;"}),
    }
    Object.values(modal).forEach(val => M.Modal.init(val))

    const audio_snippet = $('.article__metadata--listen .article__audio_controls')

    const ma = $(modal.audio)
    ma.append(audio_snippet.clone(true, true))
    ma.css('background-color', 'lime')
    ma.css('box-shadow', 'none')
    ma.css('padding', '20% 0')
    ma.css('text-align', 'center')
    const ac =$(modal.audio).find('.article__audio_control')
    ac.css('color', 'white')
    ac.css('background-color', 'black')
    ac.css('border-radius', '50%')
    ac.css('margin', '0 0.2em')
    ac.css('padding', '0.1em')

    document.body.append(
        crel('span', {class: 'materializecss', style: 'width: 100%;'},
            (audio_snippet.length ? modal.audio : null)
        )
    )
})()