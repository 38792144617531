import "core-js/stable"
import "regenerator-runtime/runtime"


import $ from 'jquery'
window.$ = $
window.jQuery = $

import 'materialize-css'
import '../../../../src/es6/lazyload_images'
import '../../../../src/es6/scripts_on_demand'
import '../../../../src/es6/materialize_init'
import '../../../../src/es6/gallery'
import '../../article/audio'
import './article/social_icons_mobile_footer'
import './audio_player'
import '../../../../src/es6/paginator'
import '../../../../src/es6/google_analytics'
import '../../../../src/es6/defer_loading_images'
import '../../../../src/es6/browser_update'
import '../../searchbox/searchbox'
import '../../../../src/es6/login'
import '../../../../src/es6/register'
import '../../../../src/es6/ella_account'
import infinite_menu from '../../../../src/es6/infinite_menu' // loaded in '../../menu/main_menu'
import '../../article/social_icons_mobile_footer'

import '../../common_snippets/newsletter'
import '../../menu/main_menu'

import '../../../../src/es6/cookies'
import 'slick-carousel'
import './fb'
import './live_net_live'
import './push_notifications'
import './midas'
import './alliance'
import './hubspot'

import '../../../../src/es6/login_after_purchase'

import '../scss/style.scss'


// infinite_menu('.menu1, .menu1Fixed')
// infinite_menu('.menuFixed')

const urlParam = function(name){
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (results) {
        return results[1] || 0;
    } else {
        return 0;
    }
}

window.addEventListener('load', (event) => {
    if (window.adBlocker === undefined) {
        console.log('Ad blocker is ON (Yes)')
        ga('send', {
            hitType: 'event',
            eventCategory: 'Adblocker',
            eventAction: 'Yes',
            eventLabel: window.location.href
        });
    } else {
        console.log('Ad blocker is OFF (No)')
        ga('send', {
            hitType: 'event',
            eventCategory: 'Adblocker',
            eventAction: 'No',
            eventLabel: window.location.href
        });
    }
});

$(document).ready(function () {
    let elems
    let instances
    let options = {
        fullWidth: true,
        indicators: true,
        noWrap: false,
        duration: 300,
    }

    var slickOpts = {
        slidesToShow: 1,
        slidesToScroll: 1,
        easing: 'swing',
        speed: 300,
        dots: true,
        autoplay: true,
        autoplaySpeed: 8000,
        arrows: false,
        customPaging: function(slick,index) {
            return '<a>' + (index + 1) + '</a>';
        }
    };

    if ($('.group_carousel_holder_3').length){
        $('.group_carousel_holder_3').slick(slickOpts);
    }
    if ($('.group_carousel_holder_4').length){
        $('.group_carousel_holder_4').slick(slickOpts);
    }
    if ($('.group_carousel_holder_vertical_4').length){
        $('.group_carousel_holder_vertical_4').slick(slickOpts);
    }


    if ($('.carousel.carousel-slider.big-authors').length && $('.carousel.carousel-slider.small-authors').length){
        elems = document.querySelectorAll('.carousel');
        instances = M.Carousel.init(elems, options);
        const big_authors = M.Carousel.getInstance($('.carousel.carousel-slider.big-authors'))
        const small_authors = M.Carousel.getInstance($('.carousel.carousel-slider.small-authors'))
        $('.search__next_author').click(function () {
            small_authors.next()
            big_authors.next()
        })

        $('.search__previous_author').click(function () {
            small_authors.prev()
            big_authors.prev()
        })
    }

    options = {
        fullWidth: true,
        indicators: true,
        noWrap: false,
        duration: 300,
    }
    if ($('.group_carousel_holder4.carousel.carousel-slider').length){
        elems = document.querySelectorAll('.carousel')
        instances = M.Carousel.init(elems, options);
        let groupCarousel4 = M.Carousel.getInstance($('.group_carousel_holder4.carousel.carousel-slider'));

        let carousel_interval = window.setInterval(function () {
            groupCarousel4.next()
        }, 10000)

        $('.indicator-item').click(function () {
            clearInterval(carousel_interval)
            carousel_interval = window.setInterval(function () {
                groupCarousel4.next()
            }, 10000)
        })
    }

    if($('.last_print').length) {
        elems = document.querySelectorAll('.last_print .carousel');
        instances = M.Carousel.init(elems, options);
        const instance = M.Carousel.getInstance($('.last_print .carousel.carousel-slider'))
        instance.itemHeight = 100
    }

    if($('.dropdown-trigger').length){
        M.Dropdown.init(document.querySelector('.dropdown-trigger'), {constrainWidth: false});
    }


    function isValidDate(d) {
        return d instanceof Date && !isNaN(d);
    }

    let selected_date = new Date();
    let param_date = urlParam('date') ? urlParam('date') : ''
    let href_date = new Date(param_date)
    if(isValidDate(href_date)) {
        selected_date = href_date;
    }

    const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' })
    const [{ value: month },,{ value: day },,{ value: year }] = dateTimeFormat .formatToParts(selected_date )
    $('#date').attr('custom_value', `${year }-${month}-${day}`)

    if($('#date').length){
        M.Datepicker.init(document.querySelector('#date'), {
            defaultDate: selected_date,
            setDefaultDate: true,
            maxDate: new Date(),
            minDate: new Date('2010-01-01'),
            yearRange: [2010,2020],
            changeMonth: true,
            changeYear: true,
            format: 'd. mmmm, yyyy',
            i18n: {
                months: ['Januar', 'Februar', 'Marec', 'April', 'Maj', 'Junij', 'Julij', 'Avgust', 'September', 'Oktober', 'November', 'December'],
                monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Mar', 'Jun', 'Jul', 'Avg', 'Sep', 'Okt', 'Nov', 'Dec'],
                weekdays: ['Nedelja', 'Ponedeljek', 'Torek', 'Sreda', 'Četrtek', 'Petek', 'Sobota'],
                weekdaysShort: ['Ned', 'Pon', 'Tor', 'Sre', 'Čet', 'Pet', 'Sob'],
                weekdaysAbbrev: ['N', 'P', 'T', 'S', 'Č', 'P', 'S'],
                cancel: 'Prekliči',
                done: 'Ok',
            },
            onClose: function (e){
                if(this.date){
                    const dateTimeFormat = new Intl.DateTimeFormat('sl-SI', { year: 'numeric', month: '2-digit', day: '2-digit' })
                    const [{ value: day },,{ value: month },,{ value: year }] = dateTimeFormat.formatToParts(this.date )
                    const category = $('#category').val() ? $('#category').val() : '';
                    console.log(`/zadnje/${year }-${month}-${day}/${category}?page=1`)
                    window.location.href = `/zadnje/?page=1&date=${year }-${month}-${day}&category=${category}`
                }
            },
            onOpen: function (){
                $('.menu0').css('z-index', 1)
                $('.menu1').css('z-index', 1)
                $('.menu2').css('z-index', 1)
                $('.menu3').css('z-index', 1)
            }
        });
    }

    if($('#category').length) {
        const category = urlParam('category') ? urlParam('category') : ''
        $(`#category option[value='${category}']`).prop('selected', true);
        const category_select = M.FormSelect.init(document.querySelector('#category'), {});

        $('#category').on('change', function(e) {
            const category = e.target.value
            const date = $('#date').attr('custom_value') ? $('#date').attr('custom_value') : '';
            window.location.href = `/zadnje/?page=1&date=${date}&category=${category}`
        });
        $('.select_category input').on('click', function(e) {
            console.log('click on category')
            $('.menu0').css('z-index', 1)
            $('.menu1').css('z-index', 1)
            $('.menu2').css('z-index', 1)
            $('.menu3').css('z-index', 1)
        });

        $('.pagination a').each(function( index ) {
            let current_href = $( this ).attr('href')
            $( this ).attr('href', `${current_href}&date=${year }-${month}-${day}&category=${category}`)
        });
    }
});
