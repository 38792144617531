import crel from 'crel'
import $ from 'jquery'
import settings from './settings'

/**
 * Login and sign up
 */


window.addEventListener('DOMContentLoaded', function() {

    const min_password_length = 4
    const modal_login = $('#modal_login')

    const login_button = $('[href="#modal_login"]')
    const logout_button = $('[href="#modal_logout"]')
    const login_form = $('.login_form')
    const modal_error = $('#modal_login_error')
    const login_form_send_button = $('.login_form__send_button')

    function get_credentials() {
        return {
            username: $('#login_form__email').val(),
            password: $('#login_form__password').val()
        }
    }

    login_button.on('click', e => {
        // crel(document.head, crel('script', {src: '//www.google.com/recaptcha/api.js?hl=sl', async: true, defer: true}))
    })


    login_form.submit(e => {
        e.preventDefault()

        // Make sure we do not send crap
        const credentials = get_credentials()
        // if(!(credentials.username.match(settings.mail_regex) && credentials.password.length >= min_password_length)) return
        if(!credentials.password.length >= min_password_length) return

        // We authenticate
        jwt.auth(credentials, res => {
            if(res.non_field_errors) {
                const content = modal_error.find('.col')
                content.empty()
                content.prepend(
                    crel('div', crel('h4', 'Pozor!'), crel('ul',
                        res.non_field_errors.map(err => crel('li', err))
                    ))
                )
                M.Modal.getInstance(modal_error).open()
            } else {
                modal_login.find('.login_form__cancel_button, login_form__send_button')
                    .each(function(i) { $(this).addClass('disabled') })
                $('.modal_login__progressbar').removeClass('hidden')
                setTimeout(() => {
                    location.reload()
                }, 3000)
            }
        })
    })

    // Login form validator
    login_form.on('change mouseup mouseout keyup', e => {
        const credentials = get_credentials()
        // credentials.username.match(settings.mail_regex) && credentials.password.length >= min_password_length
        credentials.password.length >= min_password_length
        ? login_form_send_button.removeClass('disabled')
        : login_form_send_button.addClass('disabled')
    })


    /**
     * Logout
     */
    const cookie = jwt.cookie.get(jwt.token)
    if(!cookie) return

    const user = jwt.parseJwt(cookie)

    const modal_logout = $('#modal_logout')

    login_button.addClass('hidden')
    logout_button.removeClass('hidden')


    $('.modal_logout__username').empty()
    $('.modal_logout__username').append(user.username)
    $('.modal_logout__email').empty()
    $('.modal_logout__email').append(user.email)


    $('[href="#!?logout"]').click(() => {
        $('.modal_logout__progressbar').removeClass('hidden')
        modal_logout.find('.btn').each(function(i) { $(this).addClass('disabled') })
        jwt.logout()
        setTimeout(() => {
            location.replace(
                location.href.split('#')[0]
            )
        }, 3000)
    })

})