import settings from "./settings";
import $ from 'jquery'

const lazyload = {}

lazyload.timeout = 2000

lazyload.bg = (data, position) => {
    if(!data) return
    let instance = data['fill-256x144']
    if(position.width >= 256)
        instance = data['fill-512x288']
    if(position.width >= 512)
        instance = data['fill-800x450']
    if(position.height == 100 && position.width == 100)
        instance = data['fill-100x100']
    return `${instance.path}?rev=${instance.rev}`
}

lazyload.images = imgset => {
    const vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    const images = imgset || [...document.querySelectorAll('.lazyload')]
    images.forEach(img => {
        if(!img.getAttribute('data-background')) return

        img.delo_data = JSON.parse(img.getAttribute('data-background').replace(/'/g, '"'))
        const position = img.getBoundingClientRect()

        if(0 < position.y && position.y < vh) {
            img.style.backgroundImage = `url(${lazyload.bg(img.delo_data, position)})`
            img.setAttribute('data-loaded', true)
        }

    })
    setTimeout(() => {
        images.filter(img => !img.getAttribute('data-loaded')).forEach(img => {
            if(!img.getAttribute('data-background')) return
            const position = img.getBoundingClientRect()
            img.style.backgroundImage = `url(${lazyload.bg(img.delo_data, position)})`
        })
    }, lazyload.timeout)
}

$(function() {
    lazyload.images()
})


export default lazyload
